import React from "react"
import { Stepper, Step } from "react-form-stepper"
import "./Stepper.css"
import PropTypes from "prop-types"

const StepperComponent = ({ setStepperState, stepperState }) => {
  // const stepperStateHanlde = stepId => {
  //   if (stepperState !== stepId) {
  //     setStepperState(stepId)
  //   }
  // }
  const steps = [
    { id: 1, text: "Create Account" },
    { id: 2, text: "Choose Plan" },
    { id: 3, text: "Set up Payment" },
  ]

  return (
    <>
      <div className="stepper-label-container">
        {steps.map(item => {
          return (
            <div
              key={item.id}
              className={
                stepperState === item.id
                  ? "stepper-label-active"
                  : "stepper-label-in-active"
              }
            >
              {item.text}
            </div>
          )
        })}
      </div>
      <Stepper activeStep={stepperState}>
        {steps.map(item => {
          return (
            <Step
              key={item.id}
            // onClick={() => stepperStateHanlde(item.id)}
            />
          )
        })}
      </Stepper>
    </>
  )
}

StepperComponent.propTypes = {
  setStepperState: PropTypes.func,
  stepperState: PropTypes.Number,
}
export default StepperComponent
