import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { extend } from 'lodash';
const baseUrl = 'https://api.sellerextra.com/';
// const baseUrl = 'http://localhost:5500/';

export const CreateUserSubscription = createAsyncThunk(
  '/stripe/create-subscription',
  async (data, { rejectWithValue, getState }) => {
    try {
      const planId = getState().Account.planId;
      console.log("i am called");
      const token = JSON.parse(localStorage.authUser).token;
      console.log("token ", token);
      console.log("state ", planId);
      extend(data, {planId, token});
      const response = await axios.post(
        `${baseUrl}stripe/create-subscription`,
        data,
        { headers : {
          Authorization: `Bearer ${token}`
        }}
      );

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RefreshToken = createAsyncThunk(
  '/baseurl/refresh-token',
  async (data, { rejectWithValue, getState }) => {
    try {
      const token = JSON.parse(localStorage.authUser).token;
      const response = await axios.post(
        `${baseUrl}refresh-token`,
        data,
        { headers : {
          Authorization: `Bearer ${token}`
        }}
      );

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetStores = createAsyncThunk(
  '/stores',
  async (data, { rejectWithValue }) => {
    try {
      const token = JSON.parse(localStorage.authUser).token;
      const response = await axios.get(
        `${baseUrl}stores`,
        { headers : {
          Authorization: `Bearer ${token}`
        }}
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetOrders = createAsyncThunk(
  '/orders',
  async (data, { rejectWithValue }) => {
    try {
      const token = JSON.parse(localStorage.authUser).token;
      const response = await axios.get(
        `${baseUrl}orders`,
        { headers : {
          Authorization: `Bearer ${token}`
        }}
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.message,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateCustomerCard = createAsyncThunk(
  'stripe/updateUserCard',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/stripe/update-customer-card',
        data
      );

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const CancelUserSubscription = createAsyncThunk(
  'stripe/user/cancelSubscription',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        '/stripe/cancel-subscription'
      );

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const userSubscription = createSlice({
  name: 'userSubscription',
  initialState: {
    status: false,
    loading: false,
    success: false,
    region: '',
    storeName: '',
    err: ''
  },
  reducers: {

    SetField(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [CreateUserSubscription.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [CreateUserSubscription.fulfilled]: (state, action) => ({
      ...state,
      status: action.payload.status,
      success: true,
      loading: false
    }),
    [CreateUserSubscription.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
    [UpdateCustomerCard.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [UpdateCustomerCard.fulfilled]: (state, action) => ({
      ...state,
      status: action.payload.status,
      success: true,
      loading: false
    }),
    [UpdateCustomerCard.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
    [CancelUserSubscription.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [CancelUserSubscription.fulfilled]: (state, action) => ({
      ...state,
      status: action.payload.status,
      success: true,
      loading: false
    }),
    [CancelUserSubscription.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    }),
    //get stores reducer
    [GetStores.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetStores.fulfilled]: (state, action) => ({
      ...state,
      status: action.payload.status,
      success: true,
      loading: false
    }),
    [GetStores.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err
    })
  }
});

const { reducer, actions } = userSubscription;
export const {
  SetField
} = actions;

export default reducer;
