import React, { useEffect } from "react";
//Icons
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RefreshToken } from '../../store/payment-slice';

export default function CustomizedSteppers() {
	const dispatch = useDispatch();
	const history = useHistory();
      
    const region = localStorage.getItem('region');
    const storeName = localStorage.getItem('storeName');

	useEffect(async() => {
			const params = new URLSearchParams(history.location.search);
            const obj = {
                authCode: params.get('spapi_oauth_code'),
				sellerId: params.get('selling_partner_id'),
				region,
				storeName
            }
			console.log('params', obj);
			if (params.get('selling_partner_id')) {
			} else {
				dispatch(SetState({ field: 'err', value: 'Error in store creation' }));
			}
            await dispatch(RefreshToken(obj));
            history.push('/store')
	}, []);

	return (
		<div bgcolor="#fff" height="100vh">
            
		</div>
	);
}