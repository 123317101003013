import React, { useState, useEffect} from "react"
import { useDispatch, useSelector } from "react-redux";
import { SetField } from '../../store/payment-slice';
import crypto from 'crypto';
const appId = 'amzn1.sp.solution.55623ade-34ec-4119-b083-ea0b8049d86b';
import {
    Button,
    Input,
    Col,
    Container,
    FormGroup,
    Label,
    Row,
    TabContent,
    TabPane,
  } from "reactstrap"
  import BootstrapTable from 'react-bootstrap-table-next';
  import paginationFactory from 'react-bootstrap-table2-paginator';
  import "react-datepicker/dist/react-datepicker.css"
  import "assets/scss/datatables.scss"  
//   redux 
import { GetStores } from '../../store/payment-slice';


const index = () => {
    const dispatch = useDispatch();
    console.log(useSelector(state => state));
    const [activeTab, setActiveTab] = useState("1");
    const [selectedRegion, setSelectedRegion] = useState('');
    const [ stores, setStores ] = useState([
        {
            id: 1, 
            storeName: 'store 1', 
            region: 'EU', 
            createdAt: '872382372873'
        }
    ]);

    const columns = [{
        dataField: 'sellerId',
        text: 'Seller Id'
    },{
        dataField: 'storeName',
        text: 'store Name'
      }, {
        dataField: 'region',
        text: 'Region'
      }, {
        dataField: 'createdAt',
        text: 'Connected At'
      }];

   const region = (event) => {
    const value = event.target.value;
    setSelectedRegion(value);
    localStorage.setItem('region', value);
    dispatch(SetField({
        field: 'region',
        value
    }))
    console.log('event', event.target.value);
   }

   const storeName = (event) => {
    const value = event.target.value;
    localStorage.setItem('storeName', value);
    dispatch(SetField({
        field: 'storeName',
        value
    }))
   }

   const openUrl = () => {
        const state = crypto.randomBytes(20).toString("hex");
        let url;
        if (selectedRegion === "EU") {
            url = `https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=${appId}&state=${state}&version=beta&redirect_uri=https://app.sellerextra.com/store-status`;
        } else {
            url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${appId}&state=${state}&version=beta&redirect_uri=https://app.sellerextra.com/store-status`;
        }
        window.open(url, "_self");
   }

   useEffect(async()=> {
       const storeList =  await dispatch(GetStores());
       console.log('data ', storeList);
       setStores(storeList.payload.stores);
   }, [])

  return (
    <React.Fragment>
      <div className="page-content" style={{height: "900px"}}>
        <Container fluid>
            <Row>
                <Col>
                <FormGroup>
                    <Label for="exampleSelect">Select Region</Label>
                    <Input type="select" name="select" id="exampleSelect" onChange={region}>
                        <option>Select store</option>
                        <option>NA</option>
                        <option>EU</option>
                    </Input>
                </FormGroup>
                </Col>
                <Col>
                <FormGroup>
                    <Label for="storeName">Store Name</Label>
                    <Input type="store" name="store" id="storeName" placeholder="Store Name" onChange={storeName} />
                </FormGroup>
                </Col>
                <Col>
                    <Button style={{marginTop: "31px"}} onClick={openUrl}>Connect Store</Button>
                </Col>
            </Row>
            <Row>
            <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="all-order">
                      <BootstrapTable keyField='id' data={stores} columns={columns} pagination={paginationFactory()} />

                    </TabPane>
                    <TabPane tabId="2" id="processing">
                      <div>
                        <BootstrapTable keyField='id' data={stores} columns={columns} pagination={paginationFactory()} />
                      </div>
                    </TabPane>
                  </TabContent>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
