import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import axios from "axios";
import store from "./store"
import {isEmpty} from "lodash";

axios.interceptors.request.use(request => {
  const userResponse = localStorage.getItem("authUser")
  if (!isEmpty(userResponse)) {
    const { token = "" } = JSON.parse(userResponse)
    request.headers.Authorization = "Bearer " + token
  }
  return request
})

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
