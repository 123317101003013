import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  CHOOSE_SUBSCRIPTION_PLAN,
  CHOOSE_SUBSCRIPTION_SUCCESSFUL,
  CHOOSE_SUBSCRIPTION_FAILED,
  SET_ACCOUNT_FIELD
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  subscriptionError: null,
  isSubscribed: false,
  planId: 0,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    case CHOOSE_SUBSCRIPTION_PLAN:
      state = {
        ...state,
        loading: true,
        subscriptionError: null,
      }
      break
    case CHOOSE_SUBSCRIPTION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        subscriptionError: null,
      }
      break
    case CHOOSE_SUBSCRIPTION_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        subscriptionError: action.payload,
      }
      break;
    case SET_ACCOUNT_FIELD:
      const { payload: { field, value } } = action;
      state[field] = value;
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
