import React, { useState, useEffect } from "react"
import { extend } from "lodash";
import "./StripePage.css"
import { CreateUserSubscription } from './../../store/payment-slice';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";


import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';

const PublicKey = 'pk_test_51KW3kbCkZ40IdTCsZFINJvbcciNpqMMqtUkKTSjMGgScSl7Us4NhS1cSr22KMmTEbDOAdORRuUO01wbhOtvSpTRi00xoU48KDa'
const stripePromise = loadStripe(PublicKey);


const loadScript = src =>
  new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.src = src
    script.addEventListener("load", () => {
      resolve({ successful: true })
    })
    script.addEventListener("error", event => {
      reject({ error: event })
    })
    document.head.appendChild(script)
  })


const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
        padding,
      },
      invalid: {
        color: "#9e2146",
      },
    },
  }
}


const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardComplete, setCardComplete] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [enableButton, setEnableButton] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    setEnableButton(!cardComplete);
  }, [cardComplete])

  const addCardButtonClick = async () => {
    if (!stripe || !elements) {
    }
    const token = await stripe.createToken(elements.getElement(CardElement));
    console.log('token', token);
    const response =  await dispatch(CreateUserSubscription({token}));
    if(response.payload.status) {
      const localStorageData = JSON.parse(localStorage.authUser);
      extend(localStorageData, {payemnt: 'data'});
      localStorage.setItem('authUser', JSON.stringify(localStorageData));
      history.push("/dashboard");
    }
    console.log(response);
  }

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#3632EB",
        // fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#3632EB"
        },
        "::placeholder": {
          color: "#87bbfd"
        }
      },
      invalid: {
        iconColor: "#FF0000",
        color: "#FF0000"
      }
    }
  };


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="FormGroup">
        <CardElement options={CARD_OPTIONS} onChange={(e) => {
          setCardComplete(e.complete);
        }} />
      </div>
      </div>
      <div className="text-center">
        <button
          type="button"
          onClick={addCardButtonClick}
          disabled={enableButton}
        >Add Card
        </button>
      </div>
    </div>
  );
};

const StripePage = () => {
  const [stripeLoaded, setStripeLoaded] = useState({})
  useEffect(() => {
    const fetchData = async () => {
      const result = await loadScript("https://js.stripe.com/v3/")
      setStripeLoaded(result)
    }
    fetchData()
  }, [])

  const ELEMENTS_OPTIONS = {
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
      }
    ]
  };

  return (
    stripeLoaded.successful ? (
      <div className="Checkout">
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm />
        </Elements>
      </div>
    ) : <div>stripe was not loading</div>
  )
}

export default StripePage
