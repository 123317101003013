import React, { useState, useEffect} from "react"
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Input,
    Col,
    Container,
    FormGroup,
    Label,
    Row,
    TabContent,
    TabPane,
  } from "reactstrap"
  import BootstrapTable from 'react-bootstrap-table-next';
  import paginationFactory from 'react-bootstrap-table2-paginator';
  import "react-datepicker/dist/react-datepicker.css"
  import "assets/scss/datatables.scss"  
//   redux 
import { GetOrders } from '../../store/payment-slice';


const index = () => {
    const dispatch = useDispatch();
    console.log(useSelector(state => state));
    const [activeTab, setActiveTab] = useState("1");
    const [ orders, setOrders ] = useState([
        {
            id: 1, 
            storeName: 'store 1', 
            region: 'EU', 
            createdAt: '872382372873'
        }
    ]);

    const columns = [{
        dataField: 'amazonOrderId',
        text: 'Order Id'
    },{
        dataField: 'createdAt',
        text: 'Order Date'
      }, {
        dataField: 'quantity',
        text: 'Units'
      }, {
        dataField: 'itemPrice',
        text: 'Price'
      }, {
        dataField: 'itemStatus',
        text: 'Status'
      }];

   useEffect(async()=> {
       const orders =  await dispatch(GetOrders());
       if (orders && orders.payload && orders.payload.orders){
        setOrders(orders.payload.orders);
       } else {
         setOrders([]);
       }
   }, [])

  return (
    <React.Fragment>
      <div className="page-content" style={{height: "900px"}}>
        <Container fluid>
            <Row>
            <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="all-order">
                      <BootstrapTable keyField='id' data={orders} columns={columns} pagination={paginationFactory()} />

                    </TabPane>
                    <TabPane tabId="2" id="processing">
                      <div>
                        <BootstrapTable keyField='id' data={orders} columns={columns} pagination={paginationFactory()} />
                      </div>
                    </TabPane>
                  </TabContent>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
