import { Button } from 'bootstrap'
import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"
import { SetField } from '../../store/payment-slice';

import { accountField } from "../../store/actions";

import { useDispatch } from "react-redux";

const CardPricing = props => {

  const dispatch = useDispatch();

  const handleChoosePlan = (planId) => {
    dispatch(accountField({ field: 'planId', value: planId }))
    props.setStepperState(3);
  }

  return (
    <React.Fragment>
      <Col xl="4" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5>{props.pricing.title}</h5>
                <p className="text-muted">{props.pricing.description}</p>
              </div>
              <div className="ms-3">
                <i
                  className={"bx " + props.pricing.icon + " h1 text-primary"}
                />
              </div>
            </div>
            <div className="py-4">
              <h2>
                <sup>
                  <small>$</small>
                </sup>{" "}
                {props.pricing.price}/{" "}
                <span className="font-size-13">{props.pricing.duration}</span>
              </h2>
            </div>

            <div className="plan-features">
              {props.pricing.features.map((feature, key) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                  {feature.title}
                </p>
              ))}
            </div>
            <div className="text-center">
            <button
              type="button"
              onClick={() => handleChoosePlan(props.pricing.id)}
            >
              Select
            </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  pricing: PropTypes.object,
  setStepperState: PropTypes.func
}

export default CardPricing
